<template>
  <div class="services">
    <PozzyServices/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyServices from '@/components/Services.vue'

export default {
  name: 'Reviews',
  components: {
    PozzyServices
  }
}
</script>
