<template>
  <v-container>
    <v-row class="text-center">
      <v-col
          class="mb-5"
          cols="12"
      >
        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">
            Pozzyvibes Services
          </h1>
        </v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="text-center">
      <v-col
          v-for="service in services"
          :key="service.title"
          :cols="8"
      >
        <v-card
            raised="true"
        >
          <v-img
              :src="service.src"
              max-width="100%"
              max-height="100%"
          ></v-img>
          <v-card-text>
            <div v-html="service.title"></div>
          </v-card-text>

          <v-card-subtitle>
            <div v-html="service.description"></div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PozzyServices',
  data: () => ({
    services: [
      {
        title: '<p><h2>We Are Excited to Announce:</h2></p>',
        description: 'The 360 Video Booth is coming!<br>' +
            'Contact us to begin your April 2024 Bookings!<br>' +
            'Starting Price: $425<br><br>' +
            '*Financing available!*<br>',
        src: require("../assets/services/dj_010.jpg"),
      },
      {
        title: '<p><h2>Checkout Our Most Popular DJ Package!</h2></p>',
        description: 'All Inclusive DJ Wedding Package:<br>' +
            'Includes everything needed for your big day!<br>' +
            '4-6 Hours of Music & Entertainment - includes ceremony, cocktail hour, & reception music<br>' +
            'Wireless mics for officiant and toasts<br>' +
            'DJ Booth Setup - your choice of Black or White DJ Booth<br>' +
            'Basic Dance Floor Lighting Setup<br>' + 
            'Up Lights for reception room<br>' +
            '*Starting Price: $1,899<br><br>'  +
            '<p><h2>Optional Add-Ons:</h2></p>' +
            '<p>Additional hours as needed<br>' +
            'Slideshow Setup (Projector and screen included)<br>' + 
            'Personalized Monogram Light<br>' +
            'Dancing on a Cloud Effect for First Dance<br>' +
            'LED Foam Glow Stick Batons<br>' +
            'Digital or Print Photo Booth<br>' +
            'Guestbook Video Booth<br>' +
            '360 Photo Booth<br>' +
            'Mosaic Photo Booth<br><br>' +
            '*Financing & services available in Florida & Colorado!*<br>',
        src: require("../assets/services/dj_008.jpg"),
      },
      {
        title: '<p><h2>Why choose Pozzyvibes?</h2></p>',
        description: '<p>Our ratings speak for themselves. In addition to having a 5 star rating on Google & Facebook, Tiffany was voted runnerup as Best Event/Wedding DJ with Pozzyvibes in the 2023 Daytona Beach News Journal Community Choice Awards. We love what we do, and it shows!</p>',
        src: require("../assets/events/best-of-best-community-choice-awards-daytona-beach-pozzyvibes.png"),
      },
    ],
  }),
}
</script>
